.accessServer_wrapper {
  min-height: max-content;
  max-height: 200px !important;
  overflow-y: auto;
}

/* width */
.accessServer_wrapper::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.accessServer_wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.accessServer_wrapper::-webkit-scrollbar-thumb {
  background: lightgray;
}

/* Handle on hover */
.accessServer_wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}
