#root {
  width: 100%;
  height: 100vh;
}

.text-truncate {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.portal-image {
  width: 113px;
  height: 150px;
  object-fit: cover;
}

.space-image {
  width: 150px;
  height: 200px;
  object-fit: cover;
}

.thumbnail-container {
  position: relative;
}

.thumbnail-image .thumbnail-video {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.thumbnail-middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.thumbnail-image-middle {
  top: 12%;
  left: 12%;
}

.thumbnail-video-middle {
  top: 9%;
  left: 6%;
}

.thumbnail-container:hover .thumbnail-image {
  opacity: 0.3;
}

.thumbnail-container:hover .thumbnail-video {
  /* opacity: 0.3; */
}

.thumbnail-container:hover .thumbnail-middle {
  opacity: 1;
}

.chakra-switch__track[data-checked] {
  background-color: #2F7F7F;
}
